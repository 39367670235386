.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}



.custom-convert-button {
  /* background-color: white; */
  color:white;
  width: 200px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-convert-button:hover {

}

.custom-convert-button:active {
  box-shadow: none;
}


.custom-download-button {
  background-color: blue;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-download-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-download-button:active {
  box-shadow: none;
}

